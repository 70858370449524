import React, { useState, useRef } from "react";
import axios from "axios";
import "../styles/Transcription.css";
import audioIcon from "../Assets/audio.webp";
import { Transcribing } from "../pages/Transcribing";
import Result from "../pages/Result";

const TranscriptionApp = () => {
  const [audioFile, setAudioFile] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [targetLang, setTargetLang] = useState("es");
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);

  const handleFileChange = (e) => {
    setAudioFile(e.target.files[0]);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async () => {
    if (!audioFile) return;
    setStatus("transcribing");
    setTranscription("");
    setTranslatedText("");
    setError(null);

    const formData = new FormData();
    formData.append("file", audioFile);

    try {
      const response = await axios.post(
        "http://localhost:5600/transcribe",
        formData
      );
      setTranscription(response.data.text);
      setStatus("success");
    } catch (error) {
      console.error("Error transcribing:", error);
      setError("Transcription failed. Please try again.");
      setStatus("error");
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      const chunks = [];

      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        setAudioBlob(audioBlob);
        setAudioFile(
          new File([audioBlob], "recorded_audio.wav", { type: "audio/wav" })
        );
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (err) {
      console.error("Error accessing microphone:", err);
      setError(
        "Microphone access denied. Please enable microphone permissions."
      );
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  if (status === "transcribing") {
    return <Transcribing />;
  }

  if (status === "success" || status === "error") {
    return (
      <Result
        fileName={audioFile?.name}
        transcription={transcription}
        translation={translatedText}
        targetLang={targetLang}
        setTargetLang={setTargetLang}
        setTranslatedText={setTranslatedText}
        setStatus={setStatus}
        error={error}
      />
    );
  }

  return (
    <div className="transcription-container">
      <section className="transcript">
        <h1 className="transcription-title">
          <span className="highlight">Transcripto</span> is your personal scribe
        </h1>
        <p className="transcription-description">
          Unlock your full productivity potential with Transcripto, an assistive
          platform that can transcribe any recording with near-human accuracy
          and translate it into your preferred language.
        </p>
        <button className="upload-button" onClick={handleUploadClick}>
          Upload free now
        </button>
      </section>

      <div className="upload-section">
        <h2 className="upload-title">01 Upload or record your audio</h2>
        <input
          type="file"
          accept="audio/*"
          onChange={handleFileChange}
          className="file-input"
          ref={fileInputRef}
        />
        <p className="or-text">or</p>
        <div className="input-container">
          <div className="text-input-wrapper">
            <button
              className={`record-button ${recording ? "recording" : ""}`}
              onClick={recording ? stopRecording : startRecording}
            >
              {recording ? "🔴 Recording..." : "🎙️ Record Audio"}
            </button>
          </div>
        </div>
        <h2 className="transcribe-title">02 Transcribe your recording</h2>
        <button
          onClick={handleUpload}
          className="transcribe-button"
          disabled={!audioFile}
          style={{ cursor: !audioFile ? "not-allowed" : "pointer" }}
        >
          Transcribe
        </button>
      </div>
    </div>
  );
};

export default TranscriptionApp;
