// Footer Component
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>© 2025 Transcripto AI. All Rights Reserved.</p>
    </footer>
  );
};

export { Footer };
