import React, { useState } from "react";
import "../styles/faq.css";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How does the service work?",
      answer:
        "Our service allows you to upload audio files and transcribes them into text with high accuracy.",
    },
    {
      question: "How is it secure?",
      answer:
        "We use advanced encryption and security measures to ensure your data remains private and secure.",
    },
    {
      question: "Is Transcripto free?",
      answer:
        "You bet it's free - and it always will be. Free services are the best services!",
    },
    {
      question: "Is there a maximum recording length?",
      answer:
        "Not that i'm aware of. We'll tackle any recording of any length!",
    },
    {
      question: "What languages does your transcription service work for?",
      answer:
        "For now, just English. But support for more languages is coming soon!",
    },
    {
      question: "How many translation languages do you support?",
      answer:
        "Our service provides translation into multiple languages including English, Spanish and French.",
    },
    {
      question: "Can I support this project?",
      answer:
        "Yes! You can support us through donations or by sharing our service with others.",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <hr className="separator" />
      <div className="faq-container">
        <h2 className="faq-title">
          FA<span>Q</span>
        </h2>
        <p className="faq-subtitle">For those curious of mind.</p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="faq-item"
              onClick={() => toggleFAQ(index)}
            >
              <div className="faq-question">{faq.question}</div>
              {openIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQ;
