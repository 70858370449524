import React from "react";
import "../styles/about.css";
import { FaPencilAlt, FaGlobe } from "react-icons/fa";

const About = () => {
  return (
    <>
      <hr className="separator" />
      <section className="about-container">
        <div className="about-header">
          <h1>
            Transcripto works in <span className="highlight">2</span> simple
            steps
          </h1>
          <p>
            Rest assured that all your information is{" "}
            <span className="link">private and secure</span>. Our clever
            platform works in clever ways so to guarantee the quality of your
            transcriptions, while ensuring that your recordings are private to
            you and you alone!
          </p>
        </div>
        <div className="steps-container">
          <div className="step-card">
            <div className="step-header">
              <span className="step-number">01</span>
              <FaPencilAlt className="step-icon" />
            </div>
            <h2>Transcribe</h2>
            <p>Transcribe your audio file with near-human accuracy.</p>
          </div>
          <div className="step-card">
            <div className="step-header">
              <span className="step-number">02</span>
              <FaGlobe className="step-icon" />
            </div>
            <h2>Translate</h2>
            <p>Languages can be hard, so enjoy your notes in your language.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
