import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import TranscriptionApp from "./components/TranscriptionApp";
import { Footer } from "./components/Footer";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Result from "./pages/Result";

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<TranscriptionApp />} />
          <Route path="/result" element={<Result />} />
        </Routes>
        <div id="about">
          <About />
        </div>
        <div id="faq">
          <FAQ />
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
