import React from "react";
import { motion } from "framer-motion";
import "../styles/transcribing.css";

// Transcribing Component
export const Transcribing = () => {
  return (
    <div className="transcribing-container">
      <h1 className="transcribing-title">Transcribing</h1>
      <p className="transcribing-subtitle">Core cylinders engaged</p>
      <div className="transcribing-bars">
        {[1, 2, 3].map((i) => (
          <motion.div
            key={i}
            className="transcribing-bar"
            animate={{ scaleX: [1, 1.5, 1] }}
            transition={{ repeat: Infinity, duration: 1, ease: "easeInOut" }}
          />
        ))}
      </div>
    </div>
  );
};
