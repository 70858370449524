import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/result.css";

const Result = ({ fileName, transcription, status }) => {
  const [language, setLanguage] = useState("en");
  const [translatedText, setTranslatedText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 429 || status === "error") {
      setTranslatedText("");
    }
  }, [status]);

  const handleTranslate = async () => {
    try {
      const response = await fetch(
        `/api/translate?text=${transcription}&lang=${language}`
      );
      if (!response.ok) throw new Error("Translation failed");
      const data = await response.json();
      setTranslatedText(data.translation);
    } catch (err) {
      console.error("Translation error:", err);
      setTranslatedText("Translation failed.");
    }
  };

const handleRetry = () => {
  navigate(0); // Reload the page to reset the app state
};

  return (
  <div className="result-container">
    <h2 className="result-title">
      Transcripto <span className="highlight">Transcription</span>
    </h2>

    <p className="result-file">
      File name: <strong>{fileName || "Unknown"}</strong>
    </p>

    {/* Check if transcription is empty or undefined */}
    {!transcription ? (
      <div className="error-message">
        <p>Transcription failed.</p>
        <button onClick={handleRetry} className="retry-btn">
          Try Again
        </button>
      </div>
    ) : (
      <>
        <audio controls className="audio-player">
          <source src="/path-to-audio-file" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>

        <div className="result-section">
          <h3 className="result-heading">Transcription</h3>
          <p className="result-text">{transcription}</p>
        </div>

        <div className="result-section">
          <h3 className="result-heading">Translation</h3>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="language-select"
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
          </select>
          <button onClick={handleTranslate} className="translate-btn">
            Translate
          </button>
          {translatedText && (
            <p className="translated-text">{translatedText}</p>
          )}
        </div>
      </>
    )}
  </div>
);

};


export default Result;
