import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "../styles/Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const refreshHome = () => {
    navigate("/");
    window.location.reload(); // Forces a full refresh
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <h1
        className="navbar-title"
        onClick={refreshHome}
        style={{ cursor: "pointer" }}
      >
        Trans<span className="highlight">cripto</span>
      </h1>

      <div className={`navbar-links ${menuOpen ? "active" : ""}`}>
        <a href="#about" className="nav-link">
          About
        </a>
        <a href="#faq" className="nav-link">
          FAQ
        </a>
        <a href="#" className="nav-link">
          Donate
        </a>
        <button className="new-button" onClick={refreshHome}>
          New +
        </button>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
    </nav>
  );
};

export { Navbar };
